<template>
  <div class="balance">
    <div class="title">余额管理</div>

    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="平安银行">
        <a-card>
          <a-row>
            <a-col :span="4" class="moneyTitle">账户余额</a-col>
            <a-col :span="8">
              <p class="moneyAcount">￥43,903.30</p>
              <p class="">
                <span class="O6D">申请支付中金额：</span
                ><span class="OA5"><span class="red">￥</span>31.928.08</span>
              </p>
              <p class="pt10">
                <a-space :size="15">
                  <span></span>
                  <span></span>
                  <span></span>
                  <a-button type="primary" @click="TopUp();visible=true">充值</a-button>
                  <a-button @click="visible2=true">提现</a-button>
                </a-space>
              </p>
            </a-col>
            <a-col :span="4">
                <a-space :size="25">
                <a-button type="primary">查询余额</a-button>
                <p style=" border-right: 1px solid rgb(225, 192, 255);height: 160px;"></p>
                </a-space>
            </a-col>
            <a-col :span="8">
              <p class="moneyTitle">本月记录</p>
              <p class="record O6D" style="padding-top: 20px">
                转入：<span class="OA5 recordMoney">
                     <span class="red">￥</span>140.000.00</span>
              </p>
              <p class="record O6D">
                转出：<span class="OA5 recordMoney">
                     <span class="red">￥</span> 89.876.80</span>
              </p>
            </a-col>
          </a-row>
        </a-card>
        <a-card title="收支明细">
          <a-space :size="20">
            <span>
                交易明细：
              <a-range-picker>
                <a-icon slot="suffixIcon" type="calendar" />
              </a-range-picker>
            </span>
            <span
              >流向：
              <a-select style="width: 120px">
                <a-select-option value="0"> 全部 </a-select-option>
                <a-select-option value="1"> 转入 </a-select-option>
                <a-select-option value="2"> 转出 </a-select-option>
              </a-select>
            </span>
            <a-button type="primary">查询</a-button>
            <a-button type="primary">上传回单</a-button>
            <a-button type="primary">批量回单</a-button>
            <a-button type="primary">下载回单</a-button>
          </a-space>
        </a-card>
        <a-table
          rowKey="id"
          :columns="columns"
          size="middle"
          :data-source="orderList.list"
          :pagination="{
            size: 'middle',
            total: getsellerlist.total,
            current: getsellerlist.pageNum,
            pageSize: getsellerlist.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: (total) => `共有 ${getsellerlist.total} 条数据`,
            showQuickJumper: true,
            onShowSizeChange: (current, pageSize) => {
              getsellerlist.pageSize = pageSize
              getsellerlist.pageNum = 1
              this.getlist()
            },
            onChange: (pageNum) => {
              getsellerlist.pageNum = pageNum
              this.getlist()
            }
          }">
          <template slot="operation" slot-scope="text, record">
              <p>查看回单</p>
              <p v-if="
                  record.taxPaymentPro === '100%' &&
                  record.invoiceStatus === 'WAIT_APPLY'
                ">
            <a-popconfirm
           cancelText="取消"
          okText="确定"
          title="确定申请?"
          @confirm="() => putInvo(record.id)">
          <a href="javascript:;" >上传</a>
            </a-popconfirm>
              </p>
              
            </template>
        </a-table>
        <!-- 平安充值 -->
      <a-modal :width="660" v-model="visible" title="平安银行充值" :visible="visible" :centered="true" :closable="false" >
       <p class="fs16">现有余额：<span class="red">￥56,880.50</span></p>
       <p class="payMoney"><a-space :size="20">
         <span>支付申请金额：￥86,500.500</span>
         <span><b class="red"><a-icon type="exclamation-circle" /></b>  余额不足支付，还需充值 ￥29,619.50</span>
         </a-space>
       </p>
       <p class="fs16 fw700 pt20">请使用绑定的银行卡向中再物贸云平台总账充值：</p>
       <p class="pl20 fs16 ">绑定银行卡号：61122121323409834</p>
       <p class="pl50 fw700 fs16 pt20">平安银行中再物贸云网平台充值账号</p>
       <p class="pl50 fs16 pt20">收款户名：浙江玖一再生供应链科技有限公司</p>
       <p class="pl50 fs16">收款账号：15985539019</p>
       <p class="pl50 fs16">收款银行：平安银行**支付</p>
      </a-modal>
      <!-- 余额提现 -->
      <a-modal :width="680" title="余额提现" v-model="visible2" :visible="visible2" :closable="false" :centered="true">
        <a-row>
          <a-col :span="24" align="center">
        <p class="hint red"><span><a-icon type="warning" /></span> 余额提现只能转向绑定的银行卡，不可使用其他银行账号</p>
          </a-col>
        </a-row>
        <a-row class="count">
          <a-col :span="3">收款银行：</a-col><a-col :span="21">中国农业银行九堡支行</a-col>
          <a-col :span="3">户名：</a-col><a-col :span="21">杭州市中拓再生资源有限公司</a-col>
          <a-col :span="3">账号：</a-col><a-col :span="21">6152423354988098</a-col>
          <a-col :span="3">转账金额：</a-col><a-col :span="21"> 元</a-col>
          <a-col :span="3">支付密码：</a-col><a-col :span="21">请输入6位数余额支付密码</a-col>
        </a-row>
      </a-modal>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
const columns = [
  { title: '交易时间', 
    scopedSlots: { customRender: 'id' }, 
    dataIndex: 'id' 
    },
  {
    title: '支付编号',
    with: '15%',
    dataIndex: 'supplierName',
    scopedSlots: { customRender: 'supplierName' }
  },
  {
    title: '出入金额账号',
    dataIndex: 'taxableAmount',
    align: 'right',
    scopedSlots: { customRender: 'taxableAmount' }
  },
  {
    title: '金额',
    width: '10%',
    align: 'right',
    dataIndex: 'vatTaxAmount',
    scopedSlots: { customRender: 'vatTaxAmount' }
  },
  {
    title: '流向',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    title: '类型',
    scopedSlots: { customRender: 'schedule' }
  },
  {
    title: '备注',
    dataIndex: 'createdTime'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  
  data() {
    return {
      columns,
      orderList: {
         list:[{id:1}]
      },
      getsellerlist: {
        pageNum: 1,
        pageSize: 10,
        totalPage: 1,
        total: 1
      },
      visible:false,
      visible2:false
    }
  },
  created() {},
  methods: {
    getlist() {},
    TopUp(){
      
    }
  }
}
</script>
<style lang="less" scoped>
.balance {
  width: 1190px;
  background-color: #fff;
  padding: 20px;
  .title {
    padding-left: 10px;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 28px;
    padding-bottom: 45px;
  }
  .moneyTitle {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.996078431372549);
    line-height: 22px;
  }
  .moneyAcount {
    color: rgba(253, 89, 0, 0.988235294117647);
    padding: 40px 0 0 20px;
    font-size: 30px;
  }
  .record {
    padding: 10px 0 0 20px;
  }
  .recordMoney {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  .pt10 {
    padding-top: 10px;
  }
  
  .O6D {
    color: #0000006d;
  }
  .OA5 {
    color: #000000a5;
  }
  
  
}
.hint{
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  background-color: rgba(128, 255, 255, 1);
}
.count{
    padding: 10px 0 30px 70px;
    line-height: 50px;
  }
.red {
    color: red;
}
.payMoney{
  margin-top: 10px;
  padding: 10px 0;
  border-radius: 5px;
  background-color: rgb(242, 242, 242);
}
.fs16{
  font-size: 16px;
}
.fw700{
  font-weight: 700;
  color: #666;
}
.pt20{
  padding-top: 20px;
}
.pl20{
  padding-left:20px;
}
.pl50{
    padding-left: 50px ;
}

/deep/.ant-modal-title{
  font-size: 18px;
}  
/deep/.ant-modal-footer {
  border: none;
}
/deep/ .ant-card{
    margin-top:30px ;
}
/deep/.ant-modal-footer{
  text-align: center;
}
</style>